.newEducationBody{
    margin: -15px 0;
    height: auto;
    margin-top: 20px;
}

.newEducationBody div{
	position: relative;
	vertical-align: top;
}

.bigCard {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: white;
  padding: 10px;
  width: -moz-available;
  width: -webkit-fill-available; 
  width: stretch;
}



.bigCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
}