.contactBody{
    height: auto;
     color: white;
     background-color: #344346;
     text-align: center;
     padding: 10px;
     padding-bottom: 30px;
   }
   
   .contactBody h1{
       color: #5b843f;
       text-decoration: none;
   }
   
   .contactBody img{
     width: calc(1vw + 3vh);
     display: inline-block;
     margin-right: 10px;
   }
   .contactBody p, .email-link {
     display: inline-block;
     height: 32px;
     margin: 0;
     vertical-align: middle;
     margin-bottom: 5px;
     max-width: 900px;
     font-size:calc(0.4vw + 1.5vh);
     margin-right: 20px;
     color: white; 
    text-decoration: none;
   }

   .email-link:hover {
    color: white; 
    text-decoration: none; 
   } 
   
   .withText{
       display: inline-block;
       opacity:0.6;
   }
   .withText:hover{
       opacity:1;
   }