.SmallBlock{
    width:210px;
    padding: 0 10px;
    text-align: center;
    color: #405830;
    white-space: normal;
    cursor: default;
    background-color: #ffffff;
}

.SmallBlock img{
    margin-top: 18px;
    height: auto;
    width: 170px;
}

.SmallBlock h3{
	padding-top: 20px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 2px;
  margin-left: 0px;
	color: #405830;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
}

.flip-card {
  width:230px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
   -webkit-transform: rotateY(0deg);
   -moz-transform: rotateY(0deg);
   -o-transform: rotateY(0deg);
   transform: rotateY(0deg);
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  -webkit-transition: transform 0.8s;
  -moz-transition: transform 0.8s;
  -o-transition: transform 0.8s;
  transform-style: preserve-3d;

}

.flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  backface-visibility: hidden;
}

.flip-card-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}


.flip-card-back img{
    width:230px;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
}


@media screen and (min-width: 2000px) {
  .SmallBlock p {
      font-size: 0.8em;
  }
  .SmallBlock h3 {
      font-size: 1em;
  }
}

.card-link {
  margin-top: 10px;
  color: #0066cc;
  text-decoration: underline;
  font-weight: bold;
}

.card-link:hover {
  color: #004099;
}
