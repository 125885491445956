.boxBody{
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    background-size:70%;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-size 0.5s;
    -webkit-transition: background-size 0.5s;
    -moz-transition: background-size 0.5s;
    -o-transition: background-size 0.5s;
    color:white;
    margin: 0;
}

.boxTitle{
    margin: 0;
    font-size: 2em;
    height: 100%;
    padding-top: 8%;
}

.boxSubTitle{
    margin-top: -85%;
    font-size: 1.1em;

}

.boxSubTitle p{
    margin:10px;
	margin-top:6px;
	float : left;
	clear:both;
    text-align: start;
}

.boxBody:hover{
    background-size:90%;
}

.hoverContainer{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity:0;
    transition: opacity 0.5s;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    cursor: pointer;
}

.hoverContainer:hover{
    opacity:1;
}