/* :root {
  --sky: #94C3F1;
  --tree: #344346;
  --water: #79B0D9;
  --shirt: #3D4D81;
  --white: #ffffff;
  --whiteDust: #f2f2f2;
} */

html {
  scroll-behavior: smooth;
}
.body{
    position: absolute;
    top:0;
    right:0;
    left:0;
    display: block;
    background-color: #f2f2f2;
    z-index: 0;
    color: #344346;;
    font-family: Georgia, 'Times New Roman', Times, serif;
    overflow: hidden;
}

h1{
	margin-top: 40px;
  color: #344346;
  font-size:calc(1.3vw + 3vh);
	text-decoration: underline;
}

h3{
	color: #405830;
  font-size:calc(0.6vw + 2.1vh);
}

h2{
	margin-top: 10px;
  color: #405830;
  font-size:calc(0.7vw + 2.4vh);

}


.navigationButtons{
    position: fixed;
    right: calc(1vw + 2vh);
    bottom:calc(1vw + 2vh);
    margin: 0;
    width: calc(5vw + 10vh);
    height: calc(2.5vw + 5vh);
}

.navigationButtons img{
    cursor: pointer;
    width: calc(2.5vw + 5vh);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    position: absolute;
    top:0;
    bottom:0;
}
#downNav{
      left:0;
}
#upNav{
      right: 0;
}
#downNav:hover{
      /* margin-bottom: calc(-1 * (1vw + 2vh)); */
      width: calc(3vw + 6vh);
}
#upNav:hover{
      /* margin-bottom: calc(1vw + 2vh); */
      width: calc(3vw + 6vh);
}

/* .upNav:hover {
    -webkit-animation: bounce 0.6s;
    animation: bounce 0.6s;
} */

.downloadMenu{
    position: absolute;
    left:0;
    top:0;
    z-index: 4;
}

.Intro{
    position: relative;
    width: 100%;
    height:100vh;
}

.experiancesSections{
    position: relative;
    width: 100%;
    display: block;
    height: auto;
    clear: both;
    text-align: center;
}
