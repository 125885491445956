.navBar{
    color:#ffffff;
    margin-top: 20px;
    text-align: center;
}

.outsideSpace{
    position: fixed;
    right:0;
    top:0;
    bottom: 0;
    left:0;
    z-index: 1;
    background-color: rgba(0,0,0,0.4);
}

.connectiondownloadContainer {
  display: inline-block;
}

.downloadContainer {
  position: absolute;
      z-index: 2;
}

.downloadContainer.open {
    position: fixed;
    left: 0;
    top:0;
  height: 100vh;
  padding: 5vw;
  overflow-y: hidden;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  z-index: 4;
  background-color: #b1f19460;
}
.downloadContainer.open::-webkit-scrollbar {
  display: none;
}

.downloadContainerContent {
  visibility: hidden;
}

.downloadContainerContent.open {
    margin-top:calc(50px - 10vw);
  visibility: visible;
  height: 100vh;
  -webkit-user-select: none; /* Safari */
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
}

.infoDisplay {
    margin-top: calc(1.5vw + 5vh);
  display:inline-block;
  color: #ffffff;
  text-align: center;
}

.infoDisplay h1{
    position: relative;
    color:#ffffff;
	text-decoration: none;
}

.divider {
  width: 60%;
  height: 0;
  border-bottom: 0.2vh solid #ffffff;
  padding-bottom: 3vh;
  display: table;
  margin: 0 auto;
}

.filesDisplay {
text-align: center;
  overflow-x: hidden;
  margin-top: 2vh;
}

.filesDisplay a{
   display: inline-block;
   margin: 10px;
   font-size:calc(0.8vw + 1.7vh);
   color: #ffffff;
}

.filesDisplay a:hover{
   text-decoration: none;
}

#nav-container {
  display: block;
  width: calc(3vw + 4vh);
  height: calc(3vw + 4vh);
  cursor: pointer;
  position: fixed;
  left: calc(1vw + 2vh);
  top:calc(1vw + 2vh);;
}

#nav-icon1 {
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: calc(0.5vw + 0.5vh);
  width: 100%;
  border-radius: calc(0.7vw + 0.7vh);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0;
}

#nav-icon1 span:nth-child(2) {
  top: calc(1vw + 1.1vh);
}

#nav-icon1 span:nth-child(3) {
  top: calc(2vw + 2.2vh);
}

#nav-icon1.open span:nth-child(1) {
    top: calc(1vw + 1.5vh);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
}

#nav-icon1.open span:nth-child(3) {
    top: calc(1vw + 1.5vh);
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}